import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceOneAll = () => {
  return (
    <>
      {/* service area start */}
      <div className='service-area style-2 pd-top-115 pd-bottom-80'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <div className='section-title text-center'>
                <h4 className='subtitle'>HİZMETLERİMİZ</h4>
                <h2 className='title'>Vip Transfer Hizmetleri</h2>
                <p>
                  Fethiye Lüx Transfer olarak, müşterilerimize en iyi hizmeti sunmak için çalışıyoruz.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/1.jpeg' alt='Fethiye Lüx Transfer' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-2.png'
                      alt='Fethiye Lüx Transfer'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Havalimanı Transferleri</h5>
                  <p>
                  Uçuş saatinize uygun olarak sizi havalimanından alır ve otelinize veya şehrin herhangi bir noktasına güvenli ve konforlu bir şekilde ulaştırırız.

                  </p>
                  {/* <div className='btn-wrap'>
                    <Link className='read-more-text' to='/parsiyel'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/2.jpeg' alt='Fethiye Lüx Transfer' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-1.png'
                      alt='Fethiye Lüx Transfer'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Özel Geziler ve Turlar</h5>
                  <p>
                  Şoförlü araç kiralama hizmetimizle, istediğiniz rotada özel turlar düzenleyebilir, şehrin tarihi ve doğal güzelliklerini keşfedebilirsiniz.

                  </p>
                  {/* <div className='btn-wrap'>
                    <Link className='read-more-text' to='/gumrukleme'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img src='assets/img/service/3.jpeg' alt='Fethiye Lüx Transfer' />
                  <div className='icon'>
                    <img
                      src='assets/img/service/service-icon-4.png'
                      alt='Fethiye Lüx Transfer'
                    />
                  </div>
                </div>
                <div className='details'>
                  <h5>Kurumsal ve Şehir İçi Transferler</h5>
                  <p>
                  Toplantı, seminer veya etkinlikleriniz için filomuzdaki lüks araçlarla güvenilir ve zamanında transfer hizmetleri sunarız.

                  </p>
                  {/* <div className='btn-wrap'>
                    <Link className='read-more-text' to='/depolama'>
                      DEVAMI{" "}
                      <span>
                        <FaArrowRight />
                      </span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceOneAll;
